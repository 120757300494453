<template>
  <div class="query_wrapper">分项查询</div>
</template>

<script>
// 引入ajax函数
// import {
//   goodsList,
//   delGoods,
//   addGoods,
//   goodsDetail,
//   goodsTypes,
//   brands,
// } from "@/api/goods";
// 引入日期工具函数
// import { getYMDHMS } from "@/utils/date";

export default {
  // 数据
  data() {
    return {};
  },

  // 方法
  methods: {
    // 当前显示页数改变时
    sizeChange(val) {
      this.pageSize = val;
      this.getData();
    },

    // 当前页改变时
    currentChange(val) {
      this.currentPage = val;
      this.getData();
    },

    // 获取数据
    async getData() {
      // let data = {
      //   page: this.currentPage,
      //   size: this.pageSize,
      //   name: this.searchForm.name,
      //   type_id: this.searchForm.type_id,
      // };
      // let res = await goodsList(data);
      // let { list, totalSize } = res.data;

      // // 解决删除小bug ( 如果当前页没有数据 且 当前页不是第一页 )
      // if (!list.length && this.currentPage !== 1) {
      //   this.currentPage -= 1; // 页码减去1
      //   this.getData(); // 再重新请求数据 渲染
      // }

      // list.forEach((v) => {
      //   v.created_at = getYMDHMS("-", ":", v.created_at * 1000);
      // });

      // this.total = totalSize;
      // this.tableData = list;
    },

    // 适配宽度
    fitWidth() {
      window.addEventListener("resize", () => {
        this.w =
          document.body.clientWidth > 1000
            ? document.body.clientWidth - 300
            : document.body.clientWidth - 165;
      });
    },
  },

  // 生命周期-实例创建完成后
  async created() {
    // 获取列表数据
    // this.getData();
    // let data = await this.getTypes(0);
    // this.oneCateOPtions = data.data;
    // this.cateOptions = data.data;

    // 适配宽度
    // this.fitWidth();
  },
};
</script>

<style lang="less" scoped>
.query_wrapper{}
</style>
