<template>
  <div class="query_wrapper">
    <el-form ref="form" :model="form" label-width="105px">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="项目地区">
            <el-cascader
              v-model="form.location"
              :options="areaOptions"
              filterable
              :props="{ value: 'value', label: 'name', children: 'city' }"
              clearable
            ></el-cascader>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="中标时间">
            <el-date-picker
              v-model="time"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="项目经理">
            <el-input
              v-model="form.manager"
              placeholder="请输入项目经理姓名"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="技术负责人">
            <el-input
              v-model="form.technician"
              placeholder="请输入技术负责人姓名"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="专职安全员">
            <el-input
              v-model="form.secure"
              placeholder="请输入专职安全员姓名"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="中标单位">
            <el-input
              v-model="form.winner"
              placeholder="请输入中标单位关键词"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="建设单位">
            <el-input
              v-model="form.owner"
              placeholder="请输入建设单位关键词"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="金额">
            <div class="flex alc">
              <el-input
                v-model.number="form.amountMin"
                placeholder="请输入最小值"
                clearable
              >
                <template slot="append">万</template>
              </el-input>

              <span class="ml10 mr10">至</span>

              <el-input
                v-model.number="form.amountMax"
                placeholder="请输入最大值"
                clearable
              >
                <template slot="append">万</template>
              </el-input>
            </div>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="业绩来源/类型">
            <el-select
              style="width: 100%"
              v-model="form.source"
              placeholder="请选择业绩所在平台"
            >
              <el-option
                v-for="item in sourceOptions"
                :key="item.name"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item label="项目名称">
        <el-row
          class="mb30"
          v-for="(item, index) in projectNameList"
          :key="'project' + index"
        >
          <el-col class="mr20" :span="21">
            <el-input
              placeholder="多关键词用空格隔开，如：园林 绿化"
              v-model="item.keyword"
              class="input-with-select"
              clearable
            >
              <el-select
                style="width: 130px"
                v-model="item.radio"
                slot="prepend"
                placeholder="请选择"
                clearable
              >
                <el-option label="包含" value="包含"></el-option>
                <el-option label="不包含" value="不包含"></el-option>
              </el-select>
            </el-input>
          </el-col>

          <el-col :span="2" class="tac">
            <el-button
              v-if="index == 0"
              type="primary"
              icon="el-icon-plus"
              plain
              @click="addName"
            >
              增加
            </el-button>

            <el-button
              v-else
              @click="delName(index)"
              type="danger"
              icon="el-icon-delete"
              circle
              plain
            ></el-button>
          </el-col>
        </el-row>
      </el-form-item>

      <el-row>
        <el-col :span="24" class="tac">
          <el-button @click="reset" icon="el-icon-refresh-right">
            重置
          </el-button>

          <el-button type="primary" @click="query" icon="el-icon-search">
            查询
          </el-button>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
// 引入ajax函数
import { searchCompany } from "@/api/itemizedQuery";
import { getDict } from "@/api/common";
// 引入区域json
import area from "@/utils/area";

export default {
  // 数据
  data() {
    return {
      form: {
        location: [], // 企业地区,
        source: "", // 来源平台
        resultType: null, // 业绩类型-预留字段
        projectType: null, // 项目类型-预留字段
        nameInclude: "", // 包含的项目名称
        nameExclude: "", // 不包含的项目名称
        manager: "", // 项目经理
        technician: "", // 技术负责人
        secure: "", // 专职安全员
        timeStart: "", // 中标时间-开始
        timeEnd: "", // 中标时间-结束
        amountMin: "", // 金额-最小
        amountMax: "", // 金额-最大
        winner: "", // 中标单位
        owner: "", // 建设单位
        pageNum: 1,
        pageSize: 20,
      },

      time: [], // 时间
      sourceOptions: [], // 业绩来源下拉数据
      // 项目名称
      projectNameList: [
        {
          radio: "",
          keyword: "",
        },
      ],

      pickerOptions: {
        shortcuts: [
          {
            text: "最近一年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365 * 3);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近五年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365 * 5);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },

  // 方法
  methods: {
    /* 获取数据 */
    // 获取列表数据
    async getData() {
      const location = this.form.location.filter((v) => v != "").join("-");
      let timeStart = "";
      let timeEnd = "";
      if (this.time.length > 0) {
        timeStart = this.time[0];
        timeEnd = this.time[1];
      }
      let params = { ...this.form, location, timeStart, timeEnd };
      console.log("params>>>", params);

      // let res = await searchResult(params);
      // this.list = res.records;
      // this.total = res.total;
    },
    // 获取下拉选项数据
    async getOptions() {
      // 业绩来源
      const sourceList = await getDict({ source: "sourceList" });
      this.sourceOptions = sourceList;
    },

    /* 操作 */
    // 添加项目名称
    addName() {
      if (this.projectNameList.length < 5) {
        this.projectNameList.push({
          radio: "",
          keyword: "",
        });
      } else {
        this.$message({ message: "最多添加5条筛选条件~", type: "warning" });
      }
    },
    // 删除项目名称
    delName(index) {
      this.projectNameList.splice(index, 1);
    },
    // 当前显示页数改变时
    sizeChange(val) {
      this.pageSize = val;
      this.getData();
    },
    // 当前页改变时
    currentChange(val) {
      this.currentPage = val;
      this.getData();
    },
    // 重置
    reset() {
      this.form = {
        location: [], // 企业地区,
        source: "", // 来源平台
        sourceCn: "", // 来源平台-中文
        resultType: null, // 业绩类型-预留字段
        projectType: null, // 项目类型-预留字段
        nameInclude: "", // 包含的项目名称
        nameExclude: "", // 不包含的项目名称
        manager: "", // 项目经理
        technician: "", // 技术负责人
        secure: "", // 专职安全员
        timeStart: "", // 中标时间-开始
        timeEnd: "", // 中标时间-结束
        amountMin: "", // 金额-最小
        amountMax: "", // 金额-最大
        winner: "", // 中标单位
        owner: "", // 建设单位
        pageNum: 1,
        pageSize: 20,
      };
      this.time = [];
      this.projectNameList = [
        {
          radio: "",
          keyword: "",
        },
      ];
    },
    // 查询
    async query() {
      this.form.pageNum = 1;
      this.getData();
    },
    // 初始化
    __init__() {
      this.areaOptions = area;
      this.getData();
      this.getOptions();
    },
  },

  // 生命周期-实例创建完成后
  async created() {
    this.__init__();
  },
};
</script>

<style lang="less" scoped>
.query_wrapper {
  .input-with-select /deep/.el-input-group__prepend {
    background-color: #fff;
  }
}
</style>
